import React from "react"
import moment from "moment"
import { Progress } from "reactstrap"
import { useSelector } from "react-redux"

import { UserState, userSelector } from "../../hasura/slices/user"

// @ts-ignore
import starIcon from "../../lib/images/star.svg"

interface Props {
  delta?: number
  goal?: number | null
  due?: string
  isBookAssignment: boolean
  title?: string
  name?: string
  onClick?: () => void
  progress: number
}

export default function AssignmentProgress(props: Props) {
  const progress = Math.round(props.progress)
  const completed = progress >= 100
  // const isComplete = props.isBookAssignment ? progress > 0 : progress >= 100

  const { isStudent }: UserState = useSelector(userSelector)

  const status = progress <= 0 ? "Not started" : props.isBookAssignment ? `${progress}% accuracy` : `${props.delta} / ${props.goal} stars`

  return (
    <div onClick={props.onClick} className={`d-flex align-items-center text-s ${props.onClick ? "pointer" : ""}`}>
      {props.due && props.title && (
        <div className={`text-left pr-2 width-250px mb-1`}>
          <p className={`${props.onClick ? "hover-primary underline" : ""} m-0`}>{props.title}</p>

          <p style={{ marginTop: "-3px" }} className="text-xxs text--gray7 mb-0">
            Due {moment(props.due).fromNow()}
          </p>
        </div>
      )}

      {props.name && <p className="width-150px text-right m-0 pr-3 nowrap">{props.name}</p>}

      {!isStudent && (
        <div className="max-width-400px flex-grow position-relative">
          <Progress
            className="border w-100 bg--white"
            style={{ height: "12px" }}
            barClassName={`${completed ? "bg--success" : "bg--primary"}`}
            value={Math.max(2, props.progress)}
          />

          {completed && <img style={{ right: "-15px", top: "-8px" }} className="icon-s position-absolute" src={starIcon} />}
        </div>
      )}

      <div className="d-flex align-items-center justify-content-end width-150px">
        <p className="mb-0 mx-2">{status}</p>
      </div>
    </div>
  )
}
