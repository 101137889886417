import React from "react"

// @ts-ignore
import starGrayIcon from "../../lib/images/star-dark-gray.svg"
// @ts-ignore
import starIcon from "../../lib/images/star.svg"
// @ts-ignore
import masteredIcon from "../../lib/images/trophy.svg"

interface Props {
  correct?: number
  mastered?: boolean
  masteredCount?: number
  seen?: number
}

export default function Experience(props: Props) {
  const { correct, mastered, masteredCount } = props

  return (
    <div className="flex-center d-flex align-items-center gap-20px mt-1">
      <div className="flex-center">
        <p className={`m-0 text-s bold text--${mastered ? "secondary" : "gray7"}`}>{correct}</p>
        {(correct || 0) > 0 && <img style={{ margin: "0 0 3px 3px" }} src={mastered ? starIcon : starGrayIcon} className="icon-xs" />}
      </div>

      {mastered && (
        <div className="flex-center">
          {masteredCount && <p className={`m-0 text-s bold text--success`}>{masteredCount}</p>}
          <img style={{ margin: "0 0 0 3px" }} src={masteredIcon} className="icon-xs" />
        </div>
      )}
    </div>
  )
}
