import React, { useState } from "react"
import { Button, Modal, ModalBody, ModalFooter, Input } from "reactstrap"

import { pluralize } from "../../lib/helpers"

interface Props {
  close: () => void
  upload: (concepts: string[]) => void
}

export default function UploadConceptsModal(props: Props) {
  const [conceptsToUpload, setConceptsToUpload] = useState<string>("")

  const conceptsToUploadSplit = conceptsToUpload.split("\n").filter((s) => s)

  return (
    <Modal size="lg" fade={false} centered isOpen={true} toggle={props.close}>
      <ModalBody className="text-center min-height-200px flex-center flex-column p-4">
        <p className="text-l bold mb-0">Upload Concepts</p>

        <p style={{ marginTop: "-2px" }} className="text-xs font-italic text--gray7">
          * upload a list of concepts separated by newlines
        </p>

        <Input
          autoFocus
          className="min-height-300px"
          type="textarea"
          value={conceptsToUpload}
          onChange={(e: any) => setConceptsToUpload(e.target.value)}
        />
      </ModalBody>

      <ModalFooter className="align-items-center flex-column">
        <Button
          className="min-width-200px height-50px flex-center"
          color="success"
          disabled={!conceptsToUploadSplit.length}
          onClick={() => props.upload(conceptsToUploadSplit)}
          size="lg"
        >
          Upload{conceptsToUploadSplit.length > 0 && ` ${pluralize("concept", conceptsToUploadSplit.length)}`}
        </Button>

        <p onClick={props.close} className="text-link text-muted">
          Cancel
        </p>
      </ModalFooter>
    </Modal>
  )
}
