import { Modal, ModalBody } from "reactstrap"
import React from "react"

import Plans from "../../premium/plans"

interface Props {
  close: () => void
}

export default function PremiumModal(props: Props) {
  return (
    <Modal className="width-650px" size="lg" fade={false} autoFocus={false} centered isOpen toggle={props.close}>
      <ModalBody className="p-4">
        <div className="mb-4">
          <p className="bold mb-1 text-l">Need more content?</p>
          <p className="text-s">Your plan only comes with 100 free words, but getting more is easy.</p>
        </div>

        <Plans />
      </ModalBody>
    </Modal>
  )
}
