import React, { useEffect, useState } from "react"
import first from "lodash/first"
import isUndefined from "lodash/isUndefined"
import moment from "moment"
import sortBy from "lodash/sortBy"
import union from "lodash/union"
import uniqBy from "lodash/uniqBy"
import { Badge, Button, Progress, Tooltip } from "reactstrap"
import { navigate } from "gatsby"
import { useDispatch, useSelector } from "react-redux"

import AssignmentProgress from "../assignments/progress"
import BookList from "../book/list"
import CONFIG from "../../config"
import ConceptCard from "../library/concept"
import PassageCard from "../library/passage"
import PlayButton from "../play/button"
import { PassagesForUser_passages } from "../../hasura/queries/types/PassagesForUser"
import { UserEvent } from "../../lib/userEventTypes"
import { User_users_by_pk_experience } from "../../hasura/queries/types/User"
import { bookSelector, BookState } from "../../hasura/slices/book"
import { conceptSelector, ConceptState, fetchConceptsAction } from "../../hasura/slices/concept"
import { curriculumSelector, CurriculumState, fetchEntityCountsAction } from "../../hasura/slices/curriculum"
import { fetchPassagesForUserAction, passageSelector, PassageState, Token } from "../../hasura/slices/passage"
import { getImagesForUser, getMasteredCount, instanceOfConceptExperience, withCommas } from "../../lib/helpers"
import { insertUserEventAction, userSelector, UserState } from "../../hasura/slices/user"
import { progressForAssignment } from "../../hasura/slices/assignment"

// @ts-ignore
import animals from "../../lib/images/animals.png"
// @ts-ignore
import startIcon from "../../lib/images/start-white.svg"
// @ts-ignore
import accuracyIcon from "../../lib/images/target.png"
// @ts-ignore
import starIcon from "../../lib/images/star.svg"

export default function StudentHome() {
  const dispatch = useDispatch()

  const { accessToken, user, hasPremium }: UserState = useSelector(userSelector)
  const { passagesForUser }: PassageState = useSelector(passageSelector)
  const { concepts }: ConceptState = useSelector(conceptSelector)
  const { entityCounts }: CurriculumState = useSelector(curriculumSelector)
  const { books }: BookState = useSelector(bookSelector)

  const [displayPlanTooltip, setDisplayPlanTooltip] = useState(false)

  useEffect(() => {
    if (!accessToken || !user) return

    dispatch(fetchPassagesForUserAction(accessToken, user.id))
    dispatch(fetchEntityCountsAction())
    dispatch(fetchConceptsAction(accessToken))
  }, [accessToken, user])

  const imagesForUser = getImagesForUser(user)

  const startOfDay = moment().startOf("day")

  const filteredConcepts = uniqBy(user?.experience.filter((e) => e.concept && moment(e.created_at).isAfter(startOfDay)) || [], "concept.display_name")

  const filteredPassages = (passagesForUser || []).filter((p) =>
    moment(user?.experience.find((e) => p.id === e.passage_id)?.created_at).isAfter(startOfDay)
  )

  const sorted: (PassagesForUser_passages | User_users_by_pk_experience)[] = sortBy(
    // @ts-ignore
    union(filteredConcepts, filteredPassages),
    // @ts-ignore
    (e) => moment(instanceOfConceptExperience(e) ? e.created_at : user?.experience.find((e2) => e?.id === e2.passage_id)?.created_at)
  )

  const totalConcepts = hasPremium ? CONFIG.TOTAL_WORDS : CONFIG.BASE_WORDS
  const totalPassages = hasPremium ? CONFIG.TOTAL_PASSAGES : CONFIG.BASE_PASSAGES
  const totalImages = CONFIG.TOTAL_IMAGES
  const filteredBooks = books.filter((b) => b.live && (b.is_basic || hasPremium))

  const userImagesCount = Math.min(getImagesForUser(user).length, totalImages)
  const userConceptsCount = Math.min(user?.experience.filter((e) => e.concept).length || 0, totalConcepts)
  const userPassagesCount = Math.min(user?.experience.filter((e) => e.passage_id).length || 0, totalPassages)
  const userBooksCount = filteredBooks.filter((d) => getMasteredCount(first(d.experiences)) > 0).length

  const imageProgress = Math.ceil((userImagesCount * 100) / totalImages)
  const conceptProgress = Math.ceil((userConceptsCount * 100) / totalConcepts)
  const passageProgress = Math.ceil((userPassagesCount * 100) / totalPassages)
  const bookProgress = Math.ceil((userBooksCount * 100) / filteredBooks.length)

  return (
    <div style={{ minHeight: `calc(100vh - ${CONFIG.HEADER_HEIGHT}px)` }} className="bg--gray2">
      <div className="m-auto max-width-1100px pb-5">
        <div style={{ gap: "20px" }} className="d-flex align-items-start mobile-flex-column pt-4">
          <div style={{ flex: 4 }} className="bg--white p-3 border-radius-10px max-width-550px">
            <div className="d-flex justify-content-between align-items-start">
              <h1 className="text-xl bold mb-0">My Progress</h1>

              <Badge
                onPointerLeave={() => setDisplayPlanTooltip(false)}
                onPointerEnter={() => {
                  if (!hasPremium) setDisplayPlanTooltip(true)
                }}
                id="PlanBadge"
                className={`text-s semibold text-white py-1 px-2 bg--${hasPremium ? "purple" : "gray"}`}
              >
                {hasPremium ? "Premium" : "Basic"}
              </Badge>

              {displayPlanTooltip && (
                <Tooltip
                  arrowClassName="tooltip-arrow-light"
                  fade={false}
                  innerClassName="tooltip-inner-light min-width-350px"
                  isOpen
                  target="PlanBadge"
                >
                  <div className="p-2">
                    <p className="text-m mb-2">Want more words and books?</p>

                    <p className="text-s m-0">
                      {user?.is_individual ? "Upgrade" : "Ask your teacher to upgrade"} to Wordcraft{" "}
                      <span className="bold text--purple">PREMIUM</span>.
                    </p>
                  </div>
                </Tooltip>
              )}
            </div>

            <div className="d-flex justify-content-between align-items-center mobile-flex-column mt-2">
              <div style={{ gap: "0 20px" }} className="d-flex">
                <div className="d-flex flex-column align-items-center">
                  <img className="icon-s mb-1" src={starIcon} />

                  <p className="min-width-80px text-center m-0 text-l bold py-1 px-2 bg--secondary rounded">{withCommas(user?.stars)}</p>

                  <p className="text-xxxs text--gray5 m-0">STARS</p>
                </div>

                <div className="d-flex flex-column align-items-center">
                  <img className="icon-s mb-1" src={accuracyIcon} />

                  <p className="min-width-80px text-center m-0 text-l bold p-1 bg--primary4 rounded">{Math.round(user?.accuracy || 100)}%</p>

                  <p className="text-xxxs text--gray5 m-0">ACCURACY</p>
                </div>
              </div>

              {entityCounts && (
                <div className="text-m border-radius-10px max-width-300px flex-grow ml-4">
                  <div>
                    <div className="d-flex justify-content-between align-items-end">
                      <p className="m-0 single-line">
                        {userConceptsCount} <span className="text--gray7">out of</span> {withCommas(totalConcepts)} words
                      </p>
                      <p className="text-xs text--gray7 m-0">{conceptProgress}%</p>
                    </div>
                    <Progress style={{ height: "8px" }} value={conceptProgress}></Progress>
                  </div>

                  <div className="mt-2">
                    <div className="d-flex justify-content-between align-items-end">
                      <p className="m-0 single-line">
                        {withCommas(userImagesCount)} <span className="text--gray7">out of</span> {withCommas(totalImages)} images
                      </p>
                      <p className="text-xs text--gray7 m-0">{imageProgress}%</p>
                    </div>
                    <Progress style={{ height: "8px" }} value={imageProgress}></Progress>
                  </div>

                  <div className="mt-2">
                    <div className="d-flex justify-content-between align-items-end">
                      <p className="m-0 single-line">
                        {withCommas(userPassagesCount)} <span className="text--gray7">out of</span> {withCommas(totalPassages)} passages
                      </p>
                      <p className="text-xs text--gray7 m-0">{passageProgress}%</p>
                    </div>
                    <Progress style={{ height: "8px" }} value={passageProgress}></Progress>
                  </div>

                  <div className="mt-2">
                    <div className="d-flex justify-content-between align-items-end">
                      <p className="m-0 single-line">
                        {withCommas(userBooksCount)} <span className="text--gray7">out of</span> {withCommas(filteredBooks.length)} books
                      </p>
                      <p className="text-xs text--gray7 m-0">{bookProgress}%</p>
                    </div>
                    <Progress style={{ height: "8px" }} value={bookProgress}></Progress>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="d-flex flex-column align-items-end" style={{ flex: 3, gap: "20px" }}>
            <div className="hide-mobile">
              <Button
                onClick={() => {
                  const params = `type=core${CONFIG.IS_DEVELOPMENT ? "&test=true" : ""}`
                  navigate(`/play?${params}`)
                  if (!user || !accessToken) return

                  const event = { user_id: user.id, user_event_type_id: UserEvent.ClickedStartPlaying }
                  dispatch(insertUserEventAction(accessToken, event))
                }}
                size="lg"
                className="flex-center"
                color="success"
              >
                Start playing
                <img className="ml-2 icon-s" src={startIcon} />
              </Button>
            </div>

            {(user?.classroom?.assignments.length || 0) > 0 ? (
              <div className="bg--white p-3 border-radius-10px w-100 min-height-100px">
                <h1 className="text-xl bold mb-0">Assignments</h1>

                <div className="mt-3">
                  {user!.classroom!.assignments.map((a, idx) => {
                    const progress = progressForAssignment(a, user!)
                    return (
                      <AssignmentProgress
                        onClick={() => navigate(a.book ? `/book?s=${a.book.seed}` : "/play?type=core")}
                        isBookAssignment={!isUndefined(a.book?.id)}
                        progress={progress.progress}
                        due={a.due_date}
                        delta={progress.delta || undefined}
                        goal={a.stars}
                        title={a.stars ? `${a.stars} stars` : a.book?.title}
                        key={idx}
                      />
                    )
                  })}
                </div>
              </div>
            ) : (
              <div className="hide-mobile" style={{ flex: 2 }} />
            )}
          </div>
        </div>

        <div className="border-radius-10px mt-5">
          <div className="d-flex justify-content-between mb-3">
            <div className="text-xl bold m-0">
              My Library <span className="regular text-m text--gray8 pl-2">(discovered today)</span>
            </div>

            <Button className="min-width-100px" onClick={() => navigate("/library")} color="primary">
              Go to my library
            </Button>
          </div>

          {sorted.length ? (
            <div style={{ gap: "30px" }} className="d-flex flex-wrap justify-content-start">
              {sorted.map((entity, idx) => {
                if (instanceOfConceptExperience(entity)) {
                  return (
                    <ConceptCard
                      concept={entity.concept!}
                      correct={entity.correct}
                      isAdmin={false}
                      key={idx}
                      obscurity={entity.concept?.obscurity || 1}
                      seen={entity.seen}
                      imagesForUser={imagesForUser}
                    />
                  )
                }

                const tokenLemmas = (entity.annotated.tokens || []).map((t: Token) => t.lemma)

                return (
                  <PassageCard
                    concepts={(concepts || []).filter((c) => tokenLemmas.includes(c.display_name))}
                    experience={user?.experience.find((e) => e.passage_id === entity.id)}
                    imagesForUser={imagesForUser}
                    key={idx}
                    passage={entity}
                  />
                )
              })}
            </div>
          ) : (
            <div className="w-100">
              <div className="flex-center flex-column w-100">
                <img className="mx-auto w-100 max-width-350px" src={animals} />

                <p className="p-4 text-m text--gray8 m-0">Click start playing to discover new words, images, and passages!</p>
              </div>
            </div>
          )}

          {sorted.length > 0 && (
            <div className="flex-center w-100 mt-3">
              <Button onClick={() => navigate("/library")} color="primary" className="min-width-100px">
                Go to my library
              </Button>
            </div>
          )}
        </div>

        <BookList />

        <PlayButton userLevel={user!.level} userStars={user!.stars} leaderboardsDisabled={Boolean(user?.classroom?.teacher.leaderboards_disabled)} />
      </div>
    </div>
  )
}
