import React, { useEffect, useState } from "react"
import Select from "react-select"
import range from "lodash/range"
import { Button, Input, Spinner } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"

import { Option } from "../../interfaces/option"
import { updateUserMetadataAction, userSelector, UserState } from "../../hasura/slices/user"
import SimplePage from "../common/simplePage"

const roles = [
  ["K-12 Teacher", "👩‍🏫"],
  ["K-12 School Administrator", "🏫"],
  ["Parent, Tutor, or Caregiver", "🏠"],
  ["Student", "👨‍🎓"],
]

interface Props {
  setDisplayOnboarding: (displayOnboarding: boolean) => void
}

enum OnboardingStep {
  Role,
  SchoolName,
  Grades,
  HowDidYouHear,
}

export default function OnboardingPage(props: Props) {
  const dispatch = useDispatch()

  const [role, setRole] = useState<string | undefined>()
  const [isNetworking, setIsNetworking] = useState(false)
  const [lowestGrade, setLowestGrade] = useState<Option | null>(null)
  const [highestGrade, setHighestGrade] = useState<Option | null>(null)
  const [schoolName, setSchoolName] = useState<string | null>(null)
  const [step, setStep] = useState(OnboardingStep.Role)

  const { accessToken, user }: UserState = useSelector(userSelector)

  const gradeOptions: Option[] = range(1, 12).map((value) => ({ value, label: `Grade ${value}` }))
  const isInSchool = [roles[0][0], roles[1][0]].includes(role || "")

  useEffect(() => {
    if (!role) return

    setStep(isInSchool ? OnboardingStep.SchoolName : OnboardingStep.Grades)
  }, [role])

  const handleClickedNext = async (source: string | null) => {
    setIsNetworking(true)
    const grades = `${lowestGrade?.value || ""} - ${highestGrade?.value || ""}`
    await dispatch(updateUserMetadataAction(accessToken!, user!.id, grades, role!, schoolName, source))
    props.setDisplayOnboarding(false)
  }

  const question = {
    [OnboardingStep.Role]: "Let's get started! What's your role?",
    [OnboardingStep.SchoolName]: "What is the name of your school?",
    [OnboardingStep.Grades]: "What grades do you teach so we can customize your curriculum?",
    [OnboardingStep.HowDidYouHear]: "How did you hear about us?",
  }[step]

  const stepIndex = {
    [OnboardingStep.Role]: undefined,
    [OnboardingStep.SchoolName]: [2, 4],
    [OnboardingStep.Grades]: isInSchool ? [3, 4] : [2, 3],
    [OnboardingStep.HowDidYouHear]: isInSchool ? [4, 4] : [3, 3],
  }[step]

  return (
    <SimplePage>
      <div className="flex-center flex-column">
        <h1 className="text-l text-center mt-5 mb-0 max-width-500px">
          <span className="data-hj-allow garamond">{question}</span>

          {stepIndex && (
            <span className="text--gray7 text-xs mt-1 d-block text-right">
              STEP {stepIndex[0]} OF {stepIndex[1]}
            </span>
          )}
        </h1>

        {step === OnboardingStep.Role && (
          <div style={{ gap: "50px" }} className="flex-center flex-wrap max-width-500px mt-5">
            {roles.map((role, idx) => (
              <Button key={idx} onClick={() => setRole(role[0])} outline style={{ height: "175px", width: "175px" }} color="primary">
                <p className="text-xxxl m-0">{role[1]}</p>
                {role[0]}
              </Button>
            ))}
          </div>
        )}

        {step === OnboardingStep.SchoolName && (
          <div className="d-flex align-items-center flex-column mt-5">
            <Input className="data-hj-allow" onChange={(e: any) => setSchoolName(e.target.value)} autoFocus placeholder="School name" type="text" />

            <Button size="lg" onClick={() => setStep(OnboardingStep.Grades)} disabled={!schoolName} className="mt-5 width-200px" color="primary">
              Next
            </Button>
          </div>
        )}

        {step === OnboardingStep.Grades && (
          <div className="d-flex align-items-center flex-column mt-5">
            <div className="flex-center align-items-center mobile-flex-column">
              <div>
                <p className="mb-1 text-xs text--gray8 bold">Lowest grade</p>

                <Select
                  className="width-200px"
                  placeholder="Grade"
                  onChange={(option) => setLowestGrade(option)}
                  isClearable
                  options={gradeOptions}
                  value={lowestGrade}
                />
              </div>

              <p className="mb-0 pt-3 mx-3">to</p>

              <div>
                <p className="mb-1 text-xs text--gray8 bold">Highest grade</p>

                <Select
                  className="width-200px"
                  placeholder="Grade"
                  onChange={(option) => setHighestGrade(option)}
                  isClearable
                  options={gradeOptions}
                  value={highestGrade}
                />
              </div>
            </div>

            <Button
              onClick={() => setStep(OnboardingStep.HowDidYouHear)}
              disabled={isNetworking || (!lowestGrade && !highestGrade)}
              className="mt-5 width-200px"
              color="primary"
              size="lg"
            >
              {isNetworking ? <Spinner size="sm" color="light" /> : "Finish"}
            </Button>
          </div>
        )}

        {step === OnboardingStep.HowDidYouHear && (
          <div className="d-flex align-items-center flex-column mt-5">
            <Button onClick={() => handleClickedNext("social_media")} className="width-200px" color="primary">
              Social media
            </Button>

            <Button onClick={() => handleClickedNext("word_of_mouth")} className="mt-3 width-200px" color="primary">
              Colleague or friend
            </Button>

            <Button onClick={() => handleClickedNext("online_search")} className="mt-3 width-200px" color="primary">
              Online search
            </Button>

            <Button onClick={() => handleClickedNext("other")} className="mt-3 width-200px" color="primary">
              Other
            </Button>

            <Button onClick={() => handleClickedNext(null)} className="mt-3 width-200px" color="dark" outline>
              Skip
            </Button>
          </div>
        )}
      </div>
    </SimplePage>
  )
}
