import React, { useEffect, useState } from "react"
import first from "lodash/first"
import sum from "lodash/sum"
import flatten from "lodash/flatten"
import moment from "moment"
import { Button } from "reactstrap"
import { navigate } from "gatsby-link"
import { useSelector, useDispatch } from "react-redux"

// import BookList from "../book/list"
import InviteTeachers from "./inviteTeachers"
import OnboardingPage from "./onboarding"
import PremiumModal from "../common/modals/premium"
import { UserEvent } from "../../lib/userEventTypes"
import { assignmentDescription, createAssignmentClassroomIdAction } from "../../hasura/slices/assignment"
import { insertUserEventAction, testAsStudentAction, userSelector, UserState } from "../../hasura/slices/user"
import { maxClassroomsReached, pluralize, trackHotjarEvent } from "../../lib/helpers"
import { usePrevious } from "../../hooks/usePrevious"

// @ts-ignore
import checkmarkIcon from "../../lib/images/checkmark.svg"
// @ts-ignore
import gearIcon from "../../lib/images/gear.svg"

export default function TeacherHome() {
  const dispatch = useDispatch()

  const { accessToken, user, hasPremium, isTeacher }: UserState = useSelector(userSelector)

  const [displayOnboarding, setDisplayOnboarding] = useState(false)
  const [displayInviteTeachers, setDisplayInviteTeachers] = useState(false)
  const [displayPremiumModal, setDisplayPremiumModal] = useState(false)
  const [maxedOutStudents, setMaxedOutStudents] = useState<number | undefined>()
  const [displayMaxedOutStudents, setDisplayMaxedOutStudents] = useState(false)

  const previousUser = usePrevious(user)

  /*
    Effects
  */

  useEffect(() => {
    if (!user || previousUser) return

    if (isTeacher && moment(user?.created_at).isAfter(moment().subtract(1, "minute")) && !user?.subrole) {
      setDisplayOnboarding(true)
      trackHotjarEvent("started_teacher_onboarding")
      return
    }

    if (moment(user?.created_at).isBefore(moment().subtract(2, "minute")) || localStorage.getItem("has_viewing_premium_modal") === "true") {
      return
    }

    localStorage.setItem("has_viewing_premium_modal", "true")
    setDisplayPremiumModal(true)
  }, [user, previousUser])

  useEffect(() => {
    if (!user || hasPremium || previousUser) return

    const maxedOutStudents = sum(user.classrooms.filter((c) => !c.archived).map((c) => c.students.filter((s) => s.concepts_discovered >= 100).length))
    if (!maxedOutStudents) return

    setMaxedOutStudents(maxedOutStudents)
    setDisplayMaxedOutStudents(true)
  }, [user, hasPremium, previousUser])

  /*
    Methods
  */

  const handleViewAsStudent = () => {
    dispatch(testAsStudentAction(true))
    if (!user || !accessToken) return

    const event = { user_id: user.id, user_event_type_id: UserEvent.ClickedViewAsStudent }
    dispatch(insertUserEventAction(accessToken, event))
  }

  const step = (n: number, complete: boolean) =>
    complete ? (
      <div className="bg--success rounded rounded-circle icon-xs flex-center mr-2">
        <img className="p-1 h-100 " src={checkmarkIcon} />
      </div>
    ) : (
      <div className="border border-primary rounded rounded-circle icon-xs flex-center mr-2">
        <p className="text-xs m-0 text--primary">{n}</p>
      </div>
    )

  const hasStudents = Boolean(user?.classrooms.some((c) => c.students.length))
  const hasViewedLibrary = moment(user?.created_at).isBefore(moment().subtract(1, "day")) || localStorage.getItem("hasViewedLibrary") === "true"
  const hideSetup = hasViewedLibrary && hasStudents

  if (displayOnboarding) return <OnboardingPage setDisplayOnboarding={setDisplayOnboarding} />

  return (
    <div className="max-width-1100px mx-auto">
      {displayPremiumModal && <PremiumModal close={() => setDisplayPremiumModal(false)} />}

      <div style={{ gap: "50px" }} className="d-flex align-items-start no-flex-mobile mt-4">
        <div style={{ flex: 2 }} className="bg--gray2 border-radius-10px p-4 mb-4">
          <h2 className="text-l m-0">👋 Welcome, {user?.display_name}!</h2>

          {!hideSetup && (
            <div className="border-radius-10px bg--white mt-3 p-3 opaque-shadow-light">
              <p className="text-m text--primary bold">Get started with these steps:</p>

              <div className="d-flex align-items-center">
                {step(1, true)}

                <p className="m-0">Create your first class</p>
              </div>

              <div className="d-flex mt-3 align-items-center">
                {step(2, hasViewedLibrary)}

                <p onClick={() => navigate("/library")} className={`m-0 ${hasViewedLibrary ? "" : "text--primary"} pointer`}>
                  Explore our Library
                </p>
              </div>

              <div className="d-flex mt-3 align-items-center">
                {step(3, hasStudents)}

                <p
                  onClick={() => navigate(`/classes?i=${user?.classrooms[0].id}&add=t`)}
                  className={`m-0 ${hasStudents ? "" : "text--primary"} pointer`}
                >
                  Add your students
                </p>
              </div>
            </div>
          )}

          <div className="text--primary text-s mt-3">
            <p onClick={handleViewAsStudent} className="mb-2 pointer hover-underline">
              View Wordcraft as a student
            </p>

            <p onClick={() => navigate("/resource-library")} className="m-0 pointer hover-underline">
              View downloadables
            </p>
          </div>
        </div>

        <div style={{ flex: 5 }}>
          {(maxedOutStudents || 0) > 0 && displayMaxedOutStudents && (
            <div className="d-flex align-items-center gap-10px p-3 bg-gray rounded mb-3">
              <p className="m-0 text-l">💡</p>

              <p className="m-0">
                <span className="semibold">You have {pluralize("student", maxedOutStudents)} maxed out on words.</span> Your basic plan includes
                access to 100 words.{" "}
                <span onClick={() => setDisplayPremiumModal(true)} className="semibold pointer">
                  Upgrade your plan
                </span>{" "}
                to unlock all Wordcraft words.
              </p>

              <p onClick={() => setDisplayMaxedOutStudents(false)} className="semibold text-s text--gray7 m-0 pointer px-3">
                Dismiss
              </p>
            </div>
          )}

          {user?.classrooms
            .filter((c) => !c.archived)
            .map((c, idx) => {
              const currentAssignment = first(c.assignments.filter((a) => moment().subtract(7, "days").isBefore(a.due_date)))

              const hasStudents = c.students.length > 0

              return (
                <div
                  className="border border-radius-15px p-4 d-flex justify-content-between align-items-center max-width-600px opaque-shadow-light position-relative mb-4"
                  key={idx}
                >
                  <div>
                    <p onClick={() => navigate(`/classes?i=${c.id}`)} className="pointer text--primary bold text-m mb-1">
                      {c.display_name}
                    </p>

                    <p
                      onClick={() => {
                        if (currentAssignment) {
                          navigate(`/assignments?i=${currentAssignment?.id}`)
                        }
                      }}
                      className={`m-0 text--gray7 ${currentAssignment ? "underline pointer" : ""}`}
                    >
                      {currentAssignment ? assignmentDescription(currentAssignment) : "No assignments"}
                    </p>
                  </div>

                  <div className="pr-4">
                    <p
                      onClick={() => navigate(`/classes?i=${c.id}${hasStudents ? "" : "&add=t"}`)}
                      className="mb-1 text-s text--primary hover-underline pointer"
                    >
                      {hasStudents ? "Manage" : "Add"} students
                    </p>

                    <p
                      onClick={() => dispatch(createAssignmentClassroomIdAction(c.id))}
                      className="mb-1 text-s text--primary hover-underline pointer single-line"
                    >
                      Create assignment
                    </p>

                    {c.students.length > 0 && (
                      <p onClick={() => navigate("/setup-game")} className="mb-1 text-s text--primary hover-underline pointer">
                        Setup game
                      </p>
                    )}
                  </div>

                  <img onClick={() => navigate(`/classes?i=${c.id}`)} src={gearIcon} className="icon-xs position-absolute t-0 r-0 m-2 pointer" />
                </div>
              )
            })}

          {(user?.group?.users.length || 0) > 1 && (
            <div className="mb-2">
              <h6 className="bold text-s mb-1">Other teachers</h6>

              <div>
                <table style={{ tableLayout: "fixed", width: "100%" }}>
                  <tr className="text-xs semibold text--gray6">
                    <th>Name</th>
                    <th>Email</th>
                    <th># Students</th>
                    <th># Stars</th>
                    <th>Date Created</th>
                  </tr>

                  {user?.group?.users
                    .filter((u) => u.id !== user.id)
                    .map((u, idx) => (
                      <tr key={idx}>
                        <td>
                          <p className="mb-0 overflow-ellipses">{u.display_name}</p>
                        </td>
                        <td>
                          <p className="mb-0 overflow-ellipses">{u.email}</p>
                        </td>
                        <td>
                          <p className="mb-0 overflow-ellipses">{sum(flatten(u.classrooms.map((c) => c.students.length)))}</p>
                        </td>
                        <td>
                          <p className="mb-0 overflow-ellipses">{sum(flatten(u.classrooms.map((c) => c.students.map((s) => s.stars))))}</p>
                        </td>
                        <td>
                          <p className="mb-0 overflow-ellipses">{moment(u.created_at).format("MMM D YYYY")}</p>
                        </td>
                      </tr>
                    ))}
                </table>
              </div>
            </div>
          )}

          <div className="d-flex gap-10px">
            <Button
              disabled={maxClassroomsReached(hasPremium, user)}
              className="width-150px"
              onClick={() => navigate("/classes?new=t")}
              outline
              color="success"
            >
              New class
            </Button>

            <Button className="width-150px" onClick={() => setDisplayInviteTeachers(true)} outline color="primary">
              Invite teachers
            </Button>
          </div>

          {displayInviteTeachers && <InviteTeachers setDisplayInviteTeachers={setDisplayInviteTeachers} />}
        </div>
      </div>

      {/* <BookList isTeacherHome /> */}
    </div>
  )
}
