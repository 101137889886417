import React, { useEffect, useState } from "react"
import { Button, Input, Modal } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"

import { UserState, userSelector } from "../../hasura/slices/user"
import { inviteTeachersAction } from "../../hasura/slices/classroom"
import { postSlackMessage } from "../../lib/helpers"

// @ts-ignore
import teachers from "../../lib/images/teachers.png"

interface Props {
  setDisplayInviteTeachers: (display: boolean) => void
}

export default function InviteTeachers(props: Props) {
  const [emails, setEmails] = useState<string | undefined>()
  const [emailsIsValid, setEmailsIsValid] = useState(false)
  const [message, setMessage] = useState<string | undefined>()

  const { accessToken, user }: UserState = useSelector(userSelector)

  const dispatch = useDispatch()
  const emailsSplit = emails?.split(/(?:,|;)+/) || []

  useEffect(() => {
    setEmailsIsValid(emailsSplit.length > 0 && emailsSplit.every((email) => email.includes("@")))
  }, [emails])

  const send = () => {
    if (!user || !accessToken) return

    dispatch(inviteTeachersAction(accessToken, user, emailsSplit, message))
    props.setDisplayInviteTeachers(false)
    postSlackMessage(`${user.display_name} invited ${emailsSplit.join(", ")} teachers to their group`)
  }

  return (
    <Modal fade={false} isOpen autoFocus={false} toggle={() => props.setDisplayInviteTeachers(false)} centered>
      <img className="rounded" src={teachers} />

      <div className="p-3">
        <h4 className="bold">Invite Other Teachers</h4>

        <div className="my-3">
          <div className="mb-2">
            <p className="bold mb-1">To:</p>
            <Input value={emails} onChange={(e: any) => setEmails(e.target.value)} autoFocus type="textarea" placeholder="name@example.com" />
          </div>

          <div>
            <p className="bold mb-1">Message:</p>
            <Input value={message} onChange={(e: any) => setMessage(e.target.value)} type="textarea" placeholder="" />
          </div>
        </div>

        <div className="d-flex justify-content-end">
          <Button onClick={send} disabled={!emailsIsValid} className="min-width-100px" color="success">
            Send
          </Button>
        </div>
      </div>
    </Modal>
  )
}
