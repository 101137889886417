import React from "react"
import { Badge, Button } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"

import { UserState, createCheckoutSessionAction, userSelector } from "../../hasura/slices/user"

export default function Plans() {
  const dispatch = useDispatch()

  const { accessToken, user }: UserState = useSelector(userSelector)

  const createCheckoutSession = () => dispatch(createCheckoutSessionAction(accessToken!, user?.related_accounts?.stripe_profile?.id!))

  return (
    <div className="d-flex w-100 max-width-600px justify-content-between w-100 mobile-flex-column mobile-align-items-center">
      <div style={{ maxWidth: "275px" }} className="flex-even border rounded p-3 d-flex flex-column justify-content-between w-100">
        <div>
          <div>
            <div className="d-flex align-items-center mb-1">
              <p className="text-l m-0 bold">Basic</p>
              <Badge className="ml-3" color="dark">
                Current Plan
              </Badge>
            </div>
            <p className="text-s text--gray6 mb-3">For trying things out.</p>
          </div>

          <div className="border-bottom mb-4 pb-2">
            <p className="text-m bold m-0">Free</p>
          </div>

          <ul className="p-0 text-s text-left" style={{ listStyleType: "none" }}>
            <li className="mb-2">
              <span className="mr-2">✅</span>100 Wordcraft words
            </li>

            <li className="mb-2">
              <span className="mr-2">✅</span>3 Wordcraft books
            </li>

            {!user?.is_individual && (
              <li>
                <span className="mr-2">✅</span>3 classrooms
              </li>
            )}
          </ul>
        </div>

        <div />
      </div>

      <div style={{ maxWidth: "275px" }} className="flex-even border rounded p-3 d-flex flex-column justify-content-between w-100">
        <div>
          <div>
            <p className="text-l mb-1 bold">Premium</p>
            <p className="text-s text--gray6 mb-3">Per teacher per month.</p>
          </div>

          <div className="border-bottom mb-4 pb-2">
            <p className="text-m bold m-0">$10</p>
          </div>

          <ul className="p-0 text-m text-left" style={{ listStyleType: "none" }}>
            <li className="mb-2 text-s">
              <span className="mr-2">✅</span>All Wordcraft words (3,000+)
            </li>
            <li className="mb-2 text-s">
              <span className="mr-2">✅</span>All Wordcraft books (20+)
            </li>
            {!user?.is_individual && (
              <li className="text-s">
                <span className="mr-2">✅</span>Unlimited classes
              </li>
            )}
          </ul>
        </div>

        <Button onClick={createCheckoutSession} className="mt-4 w-100" color="purple">
          Upgrade to Premium
        </Button>
      </div>
    </div>
  )
}
