import React from "react"
import { Container, Navbar } from "reactstrap"

import config from "../../config"

export default function SimplePage(props: any) {
  return (
    <div className="position-fixed vw-100 vh-100 t-0 r-0 zztop">
      <Navbar style={{ height: config.HEADER_HEIGHT }} sticky="top" color="white" expand="md" className="p-0" light>
        <Container className="border-bottom">
          <h1 className={`text-secondary text-l m-0 p-0 text-uppercase bold w-100 text-center `}>WORDCRAFT</h1>
        </Container>
      </Navbar>

      {props.children}
    </div>
  )
}
